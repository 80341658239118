/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import stripJsonComments from 'strip-json-comments'
import qs from 'qs'
import { wait } from '../wait'

interface AxiosGetOptions extends AxiosRequestConfig {
  raw?: boolean
}

interface AxiosPostOptions extends AxiosRequestConfig {
  data: Record<string, unknown>
}

interface Params {
  [key: string]: string | boolean | undefined
  utf8: '✓'
  form_type: string
  _method?: string | boolean
}

const axiosGetSettings: AxiosRequestConfig = {
  method: 'GET',
  dataType: 'json',
} as any

const axiosPostSettings: AxiosRequestConfig = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
}

const axiosGet = async (url: string, options: AxiosGetOptions = {}): Promise<any> => {
  const response: AxiosResponse = await axios.request({
    ...axiosGetSettings,
    ...options,
    url,
  })
  if (options.raw) return response
  if (typeof response.data === 'string') {
    try {
      return JSON.parse(stripJsonComments(response.data))
    } catch (error) {
      console.error('Error parsing JSON response:', error)
      return {}
    }
  }
  return response.data
}

const axiosPost = async <T>(
  url: string,
  { data }: AxiosPostOptions,
  options: AxiosRequestConfig = {}
): Promise<T> => {
  const response: AxiosResponse = await axios.request({
    ...axiosPostSettings,
    ...options,
    data,
    url,
  })
  return response.data
}

export function formatParams(
  data: Record<string, unknown>,
  formType: string,
  _method: string | boolean = false
): Params {
  const params: Params = {
    utf8: '✓',
    form_type: formType,
  }
  if (_method) {
    params._method = _method
  }
  // Add attributes
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      if (data[key] !== null && data[key] !== undefined && data[key] !== '') {
        params[key] = data[key] as string
      }
    }
  }
  console.log('Formatted params:', params)
  return params
}

declare global {
  interface Window {
    Shopify: {
      captcha: {
        protect: (form: HTMLFormElement, callback?: () => void) => void
      }
    }
  }
}

const axiosForm = async function (
  url: string,
  input: { form_type: string; utf8: '✓'; [key: string]: any },
  type: string
): Promise<any> {
  const params = { ...input }

  try {
    // Create form
    const form = document.createElement('form')
    form.setAttribute('action', url)
    form.setAttribute('method', 'POST')
    form.setAttribute('style', 'display: none')
    form.setAttribute('data-shopify-captcha', 'true')
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        if (params[key] !== null && params[key] !== undefined && params[key] !== '') {
          const input = form.appendChild(document.createElement('input'))
          input.setAttribute('type', 'hidden')
          input.setAttribute('name', key)
          input.setAttribute('value', String(params[key]))
          form.appendChild(input)
        }
      }
    }

    document.body.appendChild(form)
    await wait(100)

    // Create a promise that resolves when the captcha and form submission are complete
    return new Promise((resolve, reject) => {
      window.Shopify.captcha.protect(form, async () => {
        console.log(form)
        form.submit()
        await wait(6000)
        reject('Form didnt submit?')
      })
    })
  } catch (error) {
    console.error('Error in axiosForm:', error)
    throw error
  }
}

export { axiosGet, axiosPost, axiosForm }
