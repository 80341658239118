<template>
  <input
    type="password"
    :value="value"
    class="input-password"
    :placeholder="placeholder"
    :required="required"
    :maxlength="limit"
    :name="name"
    autocomplete="current-password"
    @keyup="$emit('keyup', $event.target.value)"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    title: String,
    placeholder: String,
    value: String,
    name: String,
    required: {
      default: false,
    },
    limit: {
      default: '',
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';
@import '~styles/frontend/helper-forms.scss';

.input-password {
  @include cx-m();
  // @include cx-uc();
  appearance: none;
  border-bottom: 1px solid #000;
  /* background: #fff; */
  padding: 10px 0;
  // box-shadow:inset 2px 2px 4px rgba(0,0,0,0.3);
  // transition:transform 0.1s ease-out, box-shadow 0.1s ease-out;
  transform: translateZ(0);
  cursor: pointer;
  width: 100%;

  &:focus {
    outline: none;
  }
}
</style>
